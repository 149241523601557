import '../../styles/authenticate.css'
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { multiFetch } from '../../utils/multiFetch';
import { useAuth } from '../../context/AuthContext';

const Register = () => {
  const navigate = useNavigate()
  const { setAuth } = useAuth()
  const [model, setModel] = useState({
    username: '',
    password: ''
  });

  const setModelValues = (inputValue, inputField) => {
    setModel(prevState => (
      {...prevState, [inputField]: inputValue}
    ))
  }
  const postRegister = async e => {
    e.preventDefault();
    try {
      let res = await multiFetch('/create-user', model, 'POST')
      let isAuth = res.authenticated
      setAuth(isAuth)
      navigate('/')
    }catch(e){
      alert(e)
    }
  }

  return (
    <div className="register">
      <p className="title">Registrarse</p>
      <form>
          <input onChange={e => setModelValues(e.target.value, 'username') } autoCapitalize="none" type="text" placeholder="Usuario" />
          <input onChange={e => setModelValues(e.target.value, 'password') } autoCapitalize="none" type="password" placeholder="Contrase&ntilde;a" />
          <button onClick={ postRegister }>Ingresar</button>
      </form>
    </div>
  );
}
 
export default Register;