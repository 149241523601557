import '../../styles/ClientList.css'

const Search = ({ search }) => {
  return (
    <div className="search">
      <form onSubmit={e => e.preventDefault()}>
        <input
          onChange={e => search(e.target.value)}
          type="text"
        />
      </form>
    </div>
  );
}
 
export default Search;