import { useEffect, useState } from 'react'
import Select from '../reusable/Select'
import Arrow from '../reusable/Arrow'
import '../../styles/Options.css'
import { useBatch } from '../../context/BatchContext'
import { useController } from '../../context/ControllerContext'
import InputOptions from './InputOptions'
import ProspectMail from './ProspectMail'
import Trash from '../reusable/Trash'

const Options = () => {
  const { controller, setCurrentBatch } = useController()
  const { batches, addBatch, deleteBatch } = useBatch()
  const [formatedOptions, setFormatedOptions] = useState(null)
  const [isActiveOptions, setIsActiveOptions] = useState(false)
  const [batchName, setBatchName] = useState('')
  const [trashOpacity, setTrashOpacity] = useState('')
  const [firstClick, setFirstClick] = useState(false)

  const formatOptions = () => {
    let formated = batches.map(batch => (
      {
        name: batch.name,
        id: String(batch._id)
      }
    ))

    setFormatedOptions(formated)
  }
  const handleChange = selectedOption => {
    let currentBatch = batches.find(batch => batch._id === selectedOption.id)
    currentBatch = currentBatch ? currentBatch : batches[0]
    setCurrentBatch(currentBatch)
  }
  const postBatch = async e => {
    e.preventDefault()
    await addBatch(batchName)
  }
  const handleDeleteBatch = async () => {
    setFirstClick(true)
    setTrashOpacity('fade-out')
    setTimeout(() => {
      setFirstClick(false)
      setTrashOpacity('')
    }, 500)
    if(firstClick){
      deleteBatch(controller.currentBatch._id)
    }
  }

  useEffect(() => {
    if(batches)
      formatOptions()
  }, [batches])

  return (
    <>
      {isActiveOptions && 
        <div className="options-wrapper">
          <Arrow onClick={ () => setIsActiveOptions(!isActiveOptions) } />
          <div className="options">
            <div className="batches">
              <Select 
                head={ controller.currentBatch }
                selectOptions={ formatedOptions }
                handleChange={ change => handleChange(change) } 
              />
              <Trash className={trashOpacity} onClick={ handleDeleteBatch } />
            </div>
            <form
              className='options-form'
            >
              <input 
                type="text"
                onChange={ (e) => setBatchName(e.target.value) }
                placeholder='Batch Name'
              />
              <button
                onClick={ postBatch }
              >
              Create</button>
            </form>
            { controller.isInput && <InputOptions />}
            { !controller.isInput && <ProspectMail />}
          </div>
        </div>
      }
      {!isActiveOptions && 
      <div className="options-btn-wrapper">
        <span
          className="options-btn"
          onClick={ () => setIsActiveOptions(!isActiveOptions) }
        >
          ...
        </span>
      </div>
      }
    </>
  );
}
 
export default Options;