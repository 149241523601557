const Trash = ({ className, onClick }) => {



  return (
    <>
      <style>{`
        .trash {
          width: 40px;
          height: 40px;
          margin-left: 30px;
        }
        .trash path {
          fill: white
        }
      `}</style>
      <svg className={`trash ${className}`} onClick={onClick} width="113" height="128" viewBox="0 0 113 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 33V29.5H8.25597L8.50793 33.2355L12 33ZM101 33L104.492 33.2355L104.744 29.5H101V33ZM17.383 112.808L20.8751 112.572L17.383 112.808ZM95.617 112.808L92.1249 112.572L95.617 112.808ZM53.5 110C53.5 111.933 55.067 113.5 57 113.5C58.933 113.5 60.5 111.933 60.5 110L53.5 110ZM32.3985 109.968C32.4907 111.899 34.1307 113.389 36.0615 113.297C37.9923 113.205 39.4828 111.565 39.3905 109.634L32.3985 109.968ZM74.6121 109.634C74.5199 111.565 76.0104 113.205 77.9412 113.297C79.872 113.389 81.512 111.899 81.6042 109.968L74.6121 109.634ZM11 22.5H102V15.5H11V22.5ZM102 29.5H11V36.5H102V29.5ZM11 29.5C9.067 29.5 7.5 27.933 7.5 26H0.5C0.5 31.799 5.20101 36.5 11 36.5V29.5ZM105.5 26C105.5 27.933 103.933 29.5 102 29.5V36.5C107.799 36.5 112.5 31.799 112.5 26H105.5ZM102 22.5C103.933 22.5 105.5 24.067 105.5 26H112.5C112.5 20.201 107.799 15.5 102 15.5V22.5ZM11 15.5C5.20101 15.5 0.5 20.201 0.5 26H7.5C7.5 24.067 9.067 22.5 11 22.5V15.5ZM12 36.5H101V29.5H12V36.5ZM97.5079 32.7645L92.1249 112.572L99.1091 113.043L104.492 33.2355L97.5079 32.7645ZM83.6442 120.5H29.3558V127.5H83.6442V120.5ZM20.8751 112.572L15.4921 32.7645L8.50793 33.2355L13.8909 113.043L20.8751 112.572ZM29.3558 120.5C24.8834 120.5 21.176 117.034 20.8751 112.572L13.8909 113.043C14.4398 121.18 21.2003 127.5 29.3558 127.5V120.5ZM92.1249 112.572C91.8239 117.034 88.1166 120.5 83.6442 120.5V127.5C91.7997 127.5 98.5602 121.18 99.1091 113.043L92.1249 112.572ZM53.5 48L53.5 110L60.5 110L60.5 48L53.5 48ZM29.4466 48.167L32.3985 109.968L39.3905 109.634L36.4386 47.833L29.4466 48.167ZM77.564 47.833L74.6121 109.634L81.6042 109.968L84.5561 48.167L77.564 47.833ZM41 7.5H73V0.5H41V7.5ZM76.5 11V19H83.5V11H76.5ZM37.5 19V11H30.5V19H37.5ZM73 7.5C74.933 7.5 76.5 9.067 76.5 11H83.5C83.5 5.20101 78.799 0.5 73 0.5V7.5ZM41 0.5C35.201 0.5 30.5 5.20101 30.5 11H37.5C37.5 9.067 39.067 7.5 41 7.5V0.5Z" fill="currentColor"/>
      </svg>
    </>
  );
}
 
export default Trash;