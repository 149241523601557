import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import LoadingScreen from "../reusable/LoadingScreen";

const Private = () => {
  const { auth: authorized } = useAuth()

  return (
    <>
      { authorized === null && <LoadingScreen /> }
      { authorized === false && <Navigate to='login'/> }
      { authorized === true && <Outlet /> }      
    </>
  )
}
 
export default Private;