import './styles/index.css';
import CManager from './components/CManager/CManager'
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Private from './components/auth/Private';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext';

function App() {
  return(
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Private />} >
              <Route index element={ <CManager /> } />
            </Route>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App;
