import '../../styles/index.css';
import Options from "./Options";
import ClientForm from "./ClientForm";
import ClientList from "./ClientList";
import Switch from "../reusable/Switch";
import { BatchProvider } from "../../context/BatchContext";
import { ControllerProvider } from "../../context/ControllerContext";

const CManager = () => {
  return (
    <ControllerProvider>
      <BatchProvider>
        <div className="c-manager">
          <Options />
          <Switch />

          <ClientForm />
          <ClientList />
        </div>
      </BatchProvider> 
    </ControllerProvider>
  );
}
 
export default CManager;