import { createContext, useContext, useState, useEffect } from "react";
import { multiFetch } from "../utils/multiFetch";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    multiFetch('/is-logged')
    .then(res => {
      setAuth(res.authenticated)
    })
  }, [])

  useEffect(() => {
    if(auth){
      navigate('/')
    }
  }, [auth])

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
