import { createContext, useContext, useState, useEffect } from "react";
import { multiFetch } from "../utils/multiFetch";

const ControllerContext = createContext();

export const useController = () => useContext(ControllerContext);

export const ControllerProvider = ({ children }) => {
  const [hasLoadedData, setHasLoadedData] = useState(0)
  const [controller, setController] = useState({
    isInput: null,
    currentBatch: null,
    unContacted: {
      isAscending: true,
      allExpanded: false,
    },
    contacted: {
      isAscending: true,
      allExpanded: false,
    }
  });

  const setCurrentBatch = batch => {
    setController(prevState => (
      {...prevState, currentBatch: batch}
    ))
  }
  //listen for global changes with controller
  useEffect(() => {
    if(hasLoadedData === 1){
      setHasLoadedData(2)
    }
    if(hasLoadedData === 2){
      multiFetch('/controller', controller, 'PUT')
    }
  }, [controller])
  //get controler's state from DB
  useEffect(() => {
    multiFetch('/controller')
    .then((data) => {
      setController(data)
      setHasLoadedData(1)
    })
  }, [])

  return (
    <ControllerContext.Provider value={{ controller, setController, setCurrentBatch }}>
      {children}
    </ControllerContext.Provider>
  );
};
