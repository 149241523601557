import '../../styles/index.css';

const Arrow = ({ onClick }) => {
  return (
    <svg onClick={ onClick } className="arrow" width="45" height="45" viewBox="0 0 45 45" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45ZM11.2929 22.7071L17.6569 29.0711C18.0474 29.4616 18.6805 29.4616 19.0711 29.0711C19.4616 28.6805 19.4616 28.0474 19.0711 27.6569L14.4142 23H34C34.5523 23 35 22.5523 35 22C35 21.4477 34.5523 21 34 21H14.4142L19.0711 16.3431C19.4616 15.9526 19.4616 15.3195 19.0711 14.9289C18.6805 14.5384 18.0474 14.5384 17.6569 14.9289L11.2929 21.2929C10.9024 21.6834 10.9024 22.3166 11.2929 22.7071Z" fill="#181818"/>
    </svg>
  );
}
 
export default Arrow;



