import { useRef, useState } from "react";
import { multiFetch } from "../../utils/multiFetch";
import { useController } from "../../context/ControllerContext";
import { useBatch } from "../../context/BatchContext";

const ProspectMail = () => {
  const { controller, setCurrentBatch } = useController()
  const { updateBatch } = useBatch()
  const [mailSubject, setMailSubject] = useState('')
  const [mailBody, setMailBody] = useState('')
  const [hasWebsite, setHasWebsite] = useState(true)
  const subjectRef = useRef()
  const bodyRef = useRef()

  const postMail = async e => {
    e.preventDefault()

    let body = {
      batchId: controller.currentBatch._id,
      hasWebsite,
      mail: {
        mailSubject,
        mailBody
      }
    }
    let updatedBatch = await multiFetch('/post-message', body, 'POST')
    
    updateBatch(updatedBatch)
    setCurrentBatch(updatedBatch)

    subjectRef.current.value = ''
    bodyRef.current.value = ''
  }

  return (
    <form
      className='options-form'
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
        <input 
          type="text"
          onChange={ e => setMailSubject(e.target.value)}
          ref={ subjectRef }
          placeholder="Mail Subject"
        />
        <textarea 
          type="text"
          onChange={ e => setMailBody(e.target.value) }
          ref={ bodyRef }
          placeholder="Mail Body"
        />
      </div>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'center'}}>
        <label title="timeGreet, prospect.name" style={{cursor: 'pointer'}}>Has Website</label>
        <input 
            type="checkbox"
            checked={ hasWebsite }
            onChange={ e => setHasWebsite(!hasWebsite) }
          />        
          <button
          onClick={ postMail }
        >
        Set</button>
      </div>
    </form>
  );
}
 
export default ProspectMail;