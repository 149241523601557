import { createContext, useContext, useEffect, useState } from "react";
import { multiFetch } from "../utils/multiFetch";
import { useController } from "./ControllerContext";

const BatchContext = createContext();

export const useBatch = () => useContext(BatchContext);

export const BatchProvider = ({ children }) => {
  const { setController, setCurrentBatch } = useController()
  const [batches, setBatches] = useState(null);
  
  const addBatch = async batchName => {
    let url = `/post-batch/${batchName}`
    let {currentBatch, userBatches} = await multiFetch(url, null, 'POST')
    setCurrentBatch(currentBatch)
    setBatches(userBatches.reverse())
  }
  const deleteBatch = async batchId => {
    let updatedBatches = await multiFetch('/delete-batch', {batchId}, 'DELETE')
    setCurrentBatch(updatedBatches[updatedBatches.length - 1])
    setBatches(updatedBatches.reverse())
  }
  const updateBatch = newBatch => {
    setBatches(prevState => 
      prevState.map(prevBatch => 
        newBatch._id === prevBatch._id
        ? newBatch
        : prevBatch
      )
    )
  }
  const updateBatchProspects = batch => {
    setBatches(prevState => 
      prevState.map(prevBatch => 
        batch._id === prevBatch._id
        ? {...prevBatch, prospects: batch.prospects}
        : prevBatch
      )
    )
  }

  //get batches
  useEffect(() => {
    multiFetch('/batches')
    .then(res => {
      let reversedBatches = res.reverse()
      setBatches(reversedBatches)
    })
  }, [])

  return (
    <BatchContext.Provider value={{ batches, setBatches, updateBatch, updateBatchProspects, addBatch, deleteBatch }}>
      {children}
    </BatchContext.Provider>
  );
};
